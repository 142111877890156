<template>
  <v-container
    id="alerts"
    fluid
    tag="section"
  >

 
        <base-material-card
          color="success"
          title="Our Success Stories"
        >
                     <input 
              type="file" 
              style="display: none" 
              ref="fileinput" 
              accept="image/*"
              @change="onfilepicked">
              <v-fab-transition>
                <v-btn
                  fab
                  small
                  absolute
                  Top
                  left
                  @click="onPickFile"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </v-fab-transition>

            <v-card 
             class="spacing-playground pa-2">
            <v-row>
            <v-col
              v-for="i in partner"
              :key="i.id"
              cols="12"
              md="2"

            >

            <v-card>
             
              <v-fab-transition>
                 <v-btn
                  fab
                  absolute
                  bottom
                  x-small
                  right
                  class="ma-1"
                  @click="deleteSS(i.id)">
                  <v-icon>mdi-delete</v-icon>
                </v-btn>

              </v-fab-transition>  
              <v-img
              contain
                :src="i.PartnerImage"
                aspect-ratio="1"
              ></v-img>
             </v-card>
            </v-col>
               
        </v-row>
           
           </v-card>
      </base-material-card>

 
    <v-row>
         
           
<v-col
cols="6"
md="12"
>
 <base-material-card
          color="success"
          title="Our Public Resource "
        >

  <v-file-input
    label="Word Of The Month"
    v-model="WORHPDF"
    outlined
    accept="application/pdf"
    dense
  ></v-file-input>


        <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="SavePDF"
              style="margin-top: 10px;">
              Save
            </v-btn>
        



</base-material-card>
</v-col>




      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="success"
          title="Products Categories"
          class="px-5 py-3"
        >
            <v-row>
           <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                   v-model="NewCategory.C_Name"
                    label="Category Name"
                    class="purple-input"
                    outlined
                    dense
                  />
                </v-col>
                <v-col
                  cols="12"
                  sm="4"
                  md="4"
                >
                  <v-text-field
                    v-model="NewCategory.Order"
                    label="Order"
                    outlined
                    dense
                    type="number"
                  ></v-text-field>
                </v-col>
                  <v-col
                  cols="12"
                  md="4"
                >
                  <v-combobox
                    v-model="NewCategory.C_Category"
                    label="Type"
                    :items="CategoriesType"
                    class="purple-input"
                    outlined
                    dense
                  />
                </v-col>
               
        </v-row>
        <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="SaveNewCategory"
              style="margin-top: 10px;">
              Add New Category
            </v-btn>
        
         <template>
                  <v-data-table
                    :headers="header"
                    :items="Category"
                    item-key="name"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        flat
                      >
                        <v-toolbar-title>Categories List</v-toolbar-title>
                        <v-divider
                          class="mx-4"
                          inset
                          vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialog"
                          max-width="500px">
                          <v-card>
                        <v-card-text>
                              <v-container>
                                <v-row>
                                  <v-col
                                    cols="12"
                                    sm="4"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="editedItem.C_Name"
                                      label="UserName"
                                      outlined
                                      dense
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="4"
                                    md="4"
                                  >
                                    <v-text-field
                                      v-model="editedItem.Order"
                                      label="Order"
                                      outlined
                                      dense
                                      type="number"
                                    ></v-text-field>
                                  </v-col>
                                  <v-col
                                    cols="12"
                                    sm="4"
                                    md="4"
                                  >
                                    <v-combobox
                                      v-model="editedItem.C_Category"
                                      label="Type"
                                      :items="CategoriesType"
                                      outlined
                                      dense
                                    ></v-combobox>
                                  </v-col>


                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="close"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="save"
                              >
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="dialogDelete" max-width="500px">
                          <v-card>
                            <v-card-title class="headline">Are you sure delete this item?</v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editItem(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        @click="deleteItem(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:item.Password="{ item }">
                      <v-text-field
                      v-model='item.MobileNo'
                      disabled
                      outlined
                      dense
                      >

                      </v-text-field>
                    </template>
                  </v-data-table>
                </template>


        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="6"
      >
        <base-material-card
          color="success"
          title="Career Type"
          class="px-5 py-3"
        >
            <v-row>
           <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                   v-model="NewCareerType.TypeName"
                    label="Career Name"
                    class="purple-input"
                    outlined
                    dense
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
            <v-btn
              color="primary"
              dark
              class="mb-2"
              @click="SaveNewCareerType">
              Add New Career Type
            </v-btn>
                </v-col>
        </v-row>

       
       
        
         <template>
                  <v-data-table
                    :headers="headerCareerType"
                    :items="CareerType"
                    item-key="name"
                    class="elevation-1"
                  >
                    <template v-slot:top>
                      <v-toolbar
                        flat
                      >
                        <v-toolbar-title>Career Type List</v-toolbar-title>
                        <v-divider
                          class="mx-4"
                          inset
                          vertical
                        ></v-divider>
                        <v-spacer></v-spacer>
                        <v-dialog
                          v-model="dialog1"
                          max-width="500px">
                          <v-card>
                        
                        <v-card-text>
                              <v-container>
                                <v-row>
                                    <v-text-field
                                      v-model="editedItem.TypeName"
                                      label="Type Name"
                                      outlined
                                      dense>
                                        
                                      </v-text-field>
                                </v-row>
                              </v-container>
                            </v-card-text>

                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="CloseEditCareerType"
                              >
                                Cancel
                              </v-btn>
                              <v-btn
                                color="blue darken-1"
                                text
                                @click="saveCareerType">
                                Save
                              </v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                        <v-dialog v-model="CareerTypedialogDelete" max-width="500px">
                          <v-card>
                            <v-card-title class="headline">Are you sure delete this item?</v-card-title>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                              <v-btn color="blue darken-1" text @click="deleteCareerTypeConfirm">OK</v-btn>
                              <v-spacer></v-spacer>
                            </v-card-actions>
                          </v-card>
                        </v-dialog>
                      </v-toolbar>
                    </template>
                    <template v-slot:item.actions="{ item }">
                      <v-icon
                        small
                        class="mr-2"
                        @click="editCareerType(item)"
                      >
                        mdi-pencil
                      </v-icon>
                      <v-icon
                        small
                        @click="deleteCareerType(item)"
                      >
                        mdi-delete
                      </v-icon>
                    </template>
                    <template v-slot:item.Password="{ item }">
                      <v-text-field
                      v-model='item.MobileNo'
                      disabled
                      outlined
                      dense>
                      </v-text-field>
                    </template>
                  </v-data-table>
                </template>


        </base-material-card>
      </v-col>

   
    </v-row>




  
  </v-container>
</template>

<script>
  import { db ,fb } from '@/db'
  export default {
    name: 'DashboardNotifications',

    data: () => ({
      color: 'info',
      WORHPDF : null,
        editedItem: {
          C_Name: '',
          Order : 1 ,
          C_Category: ''
        },
        NewCategory: {
          C_Name: '',
          Order : 1 ,
          C_Category: '',
        },
         NewCareerType: {
          CareerType: '',
        },
        NewUnit: {
          SolutionType: '',
          SolutionTitle: '',
          SolutionDesc: '',
          WhySolution : {},
          Modules : {}

        },
        CategoriesType: [
        'Printing Solutions',
        'Scanning Solutions'],
      colors: [
        'info',
        'success',
        'warning',
        'error',
      ],
      SolutionType: [
        'SupplyChain',
        'BlockChain',
        'IncubeSolution',
      ],
      dialog: false,
      dialog1: false,
      dialog2: false,
      direction: 'top center',
      directions: [
        'top left',
        'top center',
        'top right',
        'bottom left',
        'bottom center',
        'bottom right',
      ],
      snackbar: false,
    }),


    computed: {
    partner(){
      return this.$store.state.Partner
    },
      parsedDirection () {
        return this.direction.split(' ')
      },
      Category(){
        return this.$store.state.Categories
      },
      CareerType(){
        return this.$store.state.CareerType
      },
      Units(){
        return this.$store.state.Units
      },
      header () {
        return [
          {
            text: 'CategoryName ',
            align: 'start',
            value: 'C_Name',
          },
          { text: 'Type', value: 'C_Category' },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
      },
      headerCareerType () {
        return [
          {
            text: 'Career Name ',
            align: 'start',
            value: 'TypeName',
          },
          { text: 'Actions', value: 'actions', sortable: false },
        ]
      },
      headerUnits () {
        return [
          {
            text: 'MainUnit ',
            align: 'start',
            value: 'MainUnit',
          },
          {
            text: 'SecUnit ',
            align: 'start',
            value: 'SecUnit',
          },
          {
            text: 'CFact ',
            align: 'start',
            value: 'CFact',
          },

          { text: 'Actions', value: 'actions', sortable: false },
        ]
      },
    },

    created(){
      this.$store.dispatch('bindUnits');
      this.$store.dispatch('bindCategories');
      this.$store.dispatch('bindCareerType');
      this.$store.dispatch('bindPartner');
    },
    methods: {
      onPickFile(){
        
        this.$refs.fileinput.click();
      },
      onfilepicked(event){
        const files = event.target.files
        let filename = files[0].name
        if (filename.lastIndexOf('.') <= 0){
          return alert('please add a valid file !')
        }

        const reader = new FileReader()
        reader.addEventListener('load' , () => {
        
       })
        reader.readAsDataURL(files[0])
        this.saveNewSuccessStories(files[0])
        
      },

      saveNewSuccessStories(Image){

        
        var uuid = require("uuid");
          var storageRef = fb.storage().ref('SuccessStories/'+uuid.v4())
          let UploadTask = storageRef.put(Image);
          UploadTask.on('state_changed' , async function(snapshot){
          }, function(error) {

          },function() {
            
            var uuid = require("uuid");
            UploadTask.snapshot.ref.getDownloadURL().then( function(downloadURL){
            db.collection('Partner').doc(uuid.v4())
                .set({PartnerImage : downloadURL}).then(_ => { 
                  alert('Added Successfully')
                })
            })
          })

      },
      randomColor () {
        this.color = this.colors[Math.floor(Math.random() * this.colors.length)]
      },
      SaveNewCategory(){
        
        db.collection('Categories')
          .add(this.NewCategory)
          .then(docRef => {
            this.NewCategory.C_Name = ''
            this.NewCategory.C_Category = ''
        })
      },
      SavePDF(){
        
                  var storageRef1 = fb.storage().ref('Resource/WORHPDF')
                  let UploadTask1 = storageRef1.put(this.WORHPDF);
                  UploadTask1.on('state_changed' , async function(snapshot){
                  }, function(error) {

                  },function() {
                  UploadTask1.snapshot.ref.getDownloadURL().then( function(downloadURL){
                alert('PDF Saved Successfully. ✔')

          })
        })
      },
      deleteSS(ID){
        if (confirm('Are you sure you want to delete Item?')) {
        var context = this;
        db.collection('Partner')
          .doc(ID)
          .delete()
          .then(docRef => {
             alert('Item Delete Successfully');     
        })
      }
      },
      SaveNewUnit(){
        db.collection('Units')
          .add(this.NewUnit)
          .then(docRef => {
            this.NewUnit.MainUnit = ''
            this.NewUnit.SecUnit = ''
            this.NewUnit.CFact = ''
         
        })
      },
      deleteItemConfirm () {
        
         db.collection('Categories')
         .doc(this.Category[this.editedIndex].id)
         .delete()
        this.closeDelete()
      },
      deleteUnitConfirm () {
         db.collection('Categories')
         .doc(this.Category[this.editedIndex].id)
         .delete()
        this.closeDelete()
      },
      closeDelete () {
        
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      editItem (item) {
        this.editedIndex = this.Category.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      editUnit (item) {
        this.editedIndex = this.Units.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog2 = true
      },
      editCareerType (item) {
        this.editedIndex = this.CareerType.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog1 = true
      },
       deleteItem (item) {
        
        this.editedIndex = this.Category.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
        let x = this.Category[editedIndex].id
      },
      deleteUnit (item) {
        this.editedIndex = this.Units.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteCareerType (item) {
        
        this.editedIndex = this.CareerType.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.CareerTypedialogDelete = true
        let x = this.CareerType[editedIndex].id
      },
      save () {
        db.collection('Categories')
         .doc(this.Category[this.editedIndex].id)
         .update(this.editedItem)
        this.dialog = false
      },
      saveCareerType () {
        db.collection('CareerType')
         .doc(this.CareerType[this.editedIndex].id)
         .update(this.editedItem)
          this.dialog1 = false
      },
      UpdateUnit () {
        
        db.collection('Units')
         .doc(this.Units[this.editedIndex].id)
         .update(this.editedItem)
          this.dialog2 = false
      },
      CloseEditCareerType(){
         this.dialog1 = false
      },
      SaveNewCareerType(){
         db.collection('CareerType')
          .add(this.NewCareerType)
          .then(docRef => {
            this.NewCareerType.TypeName = ''
           
        })
      },
      deleteCareerTypeConfirm () {
        
         db.collection('CareerType')
         .doc(this.CareerType[this.editedIndex].id)
         .delete()
        this.CareerTypedialogDelete = false
      },
    },
  }
</script>
